<template>
  <div>
        <div class="fixed_top">
            <div class="cash_box display_flex justify-content_flex-justify align-items_center" :class="{'is_fixed' : isFixed}">
                <div class="cash_total">
                    <span>汇总</span>
                    <p>{{total_real_income}}</p>
                </div>
                <div class="cash_game display_flex align-items_center" @click="ListClick">
                    <p>{{gameList[gameCur].game_name}}</p>
                    <img src="img/game/down.png" alt="">
                </div>
                <div class="cash_Glist" v-if="showList">
                    <div class="cash_sort" style="background-image: url(img/game/sort.png);"></div>
                    <div class="cash_Gitem" :class="{'game_cur' : gameCur == index}" v-for="(item,index) in gameList" :key="index" @click="gameSelect(index)">{{item.game_name}}</div>
                </div>
            </div>
        </div>
        <div class="appcash_list">
          <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh" class="padding-bottom-xl padding-lr-sm" style="min-height: 300px;">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="cashList">
            <div class="cash_item" v-for="(item,index) in orderList" :key="index">
                <div class="cash_time display_flex justify-content_flex-justify align-items_center">
                    <p>{{status(item.status)}}</p>
                    <span>{{item.create_time}}</span>
                </div>
                <div class="cash_detail display_flex align-items_center">
                    <img :src="item.pic" alt="">
                    <div class="cash_text margin-left">
                        <p>{{item.nickname}}</p>
                        <div class="display_flex align-items_center margin-top-sm">
                            <img :src="item.logo" alt="">
                            <span class="margin-lr-xs">{{item.game_name}}</span>
                            <span class="margin-right-xs">{{item.price}}</span>
                            <img src="img/bi.png" alt="">
                            <span>/局</span>
                            <span class="margin-left-xs">x{{item.num}}</span>
                        </div>
                    </div>
                </div>
                <div class="cash_money display_flex justify-content_flex-justify align-items_center">
                    <div class="display_flex align-items_center">
                        <span>已支付</span>
                        <p class="margin-lr-xs">{{item.total_price}}</p>
                        <img src="img/bi.png" alt="">
                    </div>
                    <div class="display_flex align-items_center">
                        <span>收益(元):</span>
                        <div class="cash_count margin-left-xs ">{{item.real_income}}</div>
                    </div>
                </div>
            </div>
            </van-list>
            <div class="noData margin-top-xl" v-if="orderList.length == 0">
              <img src="img/noData.png" alt="">
            </div>
            </van-pull-refresh>
        </div>
  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/game.css"
import NavBar from '@/components/navBar.vue';
import {gameList,orderEarnings} from "@/api/api";
export default {
  name: 'appGameCash',
  components: {
    NavBar
  },
  data() {
    return {
      isFixed:'',
      showList:false,
      gameCur:0,
      gameList:[
          {
              game_name:'全部',
              type:1
          }
      ],
      orderList:[],
      total_real_income:0,
      page:1,
      row:10,
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  computed:{
    // 订单状态1待确认  2已取消  3已拒绝  4进行中 5已退款 6退款中 7已完成
    status(){
      return function(e){
        if(e == 1){
          return '待确认'
        }else if(e == 2){
          return '已取消'
        }else if(e == 3){
          return '已拒绝'
        }else if(e == 4){
          return '已拒绝'
        }else if(e == 5){
          return '进行中'
        }else if(e == 6){
          return '进行中'
        }else if(e == 7){
          return '进行中'
        }else if(e == 8){
          return '进行中'
        }else if(e == 9){
          return '已退款'
        }else if(e == 10){
          return '已退款'
        }else if(e == 11){
          return '退款中'
        }else if(e == 12){
          return '已完成'
        }else if(e == 13){
          return '已完成'
        }
      }
    }
  },
  mounted(){
    window.addEventListener('scroll',this.handleScroll)
  },
  created() {
    let access_token = this.$route.query.access_token
    localStorage.setItem('access_token', access_token)
    this.fetchData()
  },
  methods: {
    fetchData() {
      gameList({game_type:3}).then(response => {
        this.gameList = this.gameList.concat(response.data)
        console.log(this.gameList)
      },
      error => {
        this.$toast.fail(error)
      }).catch((error) => {
        this.$toast.fail(error)
      })
    },
    cashList(){
      if (this.refreshing) {
        this.orderList = [];
        this.page = 1
        this.refreshing = false;
      }
      if(this.gameCur == 0){
        var param = {
            page:this.page,
            rows:this.row
          }
      }else{
        var param = {
            page:this.page,
            rows:this.row,
            game_id:this.gameList[this.gameCur].id
          }
      }
      
      orderEarnings(param).then(response => {
        this.orderList = this.orderList.concat(response.data.list);
        this.total_real_income = response.data.total_real_income
        this.loading = false;
        if (response.data.list.length < 10) {
          this.finished = true;
        }
        this.page = this.page + 1
      },
      error => {
        this.$toast.fail(error)
      }).catch((error) => {
        this.$toast.fail(error)
      })
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.cashList();
    },
    ListClick(){
        let	state = this.showList === true ? false : true;
		    this.showList = state;
    },
    gameSelect(e){
        this.gameCur = e
        this.showList = false
        this.orderList = [];
        this.page = 1
        this.loading = true;
        this.finished = false;
        this.cashList();
    },
    closePage(){
        let params = '调用'
        this.$bridge.callhandler('closePage', params, (data) => {
        // 处理返回数据
        })
    },
    handleScroll(){
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
        this.isFixed = scrollTop > 0 ? true : false;  // 如果滚动到顶部了，this.isFixed就为true
    }

  },
};
</script>
<style>
.van-pull-refresh__track{
  position: static;
}
.van-pull-refresh__head{
  z-index: -1;
}
</style>
